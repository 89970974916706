/* CSS Document */

.black_box {
    width:500px;
    height:200px;
    background:#e08183;
    float:left;
    border:0px solid #000;
    outline: 1px solid #fff;
    outline-offset: -10px;
}
/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  /*padding-top: 200px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  margin: 0 auto;
  padding: 20px;
  /*width: 80%;*/
  width:550px;
  max-width:95%;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background:#f7a8ae;
  border:0px solid #000;
  outline: 1px solid #fff;
  outline-offset: -10px; 
  border-radius:10px;
}

.modal-content-1
{font-family: "Sentinel SSm A", "Sentinel SSm B";
font-style: normal;
font-weight: 700;
font-size:30px;
color:#ffffff;
text-align:center;
padding:10px 0px 10px 0px;}

.modal-content-2
{font-family: "Gotham SSm A", "Gotham SSm B";font-style: normal;font-weight: 400;color:#ffffff;text-align:center;padding:10px 0px 10px 0px;}

.modal-content-3
{text-align:center;}

.modal-content-button
{
 background: #f27885;
 width:80%;
 color:#ffffff !important;
 padding:10px;max-width:245px;
 margin-top:12px;
 font-family: "Sentinel SSm A", "Sentinel SSm B";
 font-style: normal;
 font-weight: 700;
}

  

/* The Close Button */
.close {
  color: #ffffff;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}


